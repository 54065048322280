import React from 'react';
import parse from 'html-react-parser';
import { Page } from '../components/Page/Page';
import { Layout } from '../components/Layout';
import { graphql } from 'gatsby';
import { SEO } from '../components/SEO';

const KitTemplate = ({ data: { kit } }) => {
  const title = String(kit.title);

  return (
    <Layout>
      <SEO />
    </Layout>
  );
};

export const pageQuery = graphql`
  query KitQuery($id: String!) {
    kit: wpModelKit(id: { eq: $id }) {
      id
      title
      manufacturer
      scale
      series
      prodnumber
      content
    }
  }
`;

export default KitTemplate;
